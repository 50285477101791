.main {
  display: flex; 
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  gap: 20px;
  padding: 40px;
}
