.label {
  display: inline-flex;
  font-size: 16px;
  line-height: 19px;
  font-weight: 400;
  color: #292929;

  &:hover {
    cursor: pointer;

    & .checkbox:before {
      background-color: rgba(255, 255, 255, var(--default-high-opacity));
    }
  }
}

.checkbox {
  display: inline-flex;
  align-items: center;
  user-select: none;

  &:before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background-color: rgba(255, 255, 255, 1);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 10px;
    border: 1px solid #cacaca;
    border-radius: 3px;
    box-shadow: var(--default-shadow);
    transition: var(--default-transition);
  }

  &.hasChildren {
    margin-left: 15px;
  }

  &.hasTitle:before {
    margin-right: 6px;
  }
}

.input {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked ~ .checkbox:before {
    background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAiIGhlaWdodD0iOSIgdmlld0JveD0iMCAwIDEwIDkiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0zLjMzMzMzIDguMTkxNjZMMCA0Ljg1ODMzTDEuMTc1IDMuNjgzMzNMMy4zMzMzMyA1LjgzMzMzTDguODI1IDAuMzQxNjZMMTAgMS41MjQ5OUwzLjMzMzMzIDguMTkxNjZaIiBmaWxsPSIjMjkyOTI5Ii8+Cjwvc3ZnPgo=');
  }
}
