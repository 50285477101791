.list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style-type: none;
}

.notFound {
  display: flex;
  align-items: center;
  min-height: 50px;
}

.list,
.notFound {
  width: 100vw;
  max-width: 250px;
  border-radius: 6px;
  transition: background-color 0.2s ease;

  &.isDraggingOver {
    background-color: #e8eaf6;
  }
}
