.task {
  display: grid;
  grid-template-columns: 1fr 42px;
  grid-gap: 10px 6px;
  width: 100%;
  padding: 12px;
  background-color: #fff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 6px;
  transition: background-color 0.2s ease;

  &.isDragging {
    background-color: #c5cae9;
  }
}

.title {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.2;
  color: #333;
}

.editLink {
  justify-self: end;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  background-color: transparent;
  border-radius: 3px;
  transition: var(--default-transition);

  &:hover {
    background-color: rgba(51, 51, 51, var(--default-low-opacity));
  }
}

.tagList {
  grid-column: 1 / 2;
  grid-row: 2 / 3;
}

.alerts {
  grid-column: 2 / 3;
  grid-row: 2 / 3;
  justify-self: end;
  align-self: end;

  display: flex;
  gap: 5px;
}

.iconDots {
  width: 12px;
  fill: #929292;
  transition: var(--default-transition);

  .editLink:hover & {
    fill: #333;
  }
}

.iconAlert,
.iconText {
  width: 16px;
  fill: #929292;
}
