.column {
  display: flex;
  flex-direction: column;
  gap: 14px;
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.title {
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  color: #333333;
}

.inner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 14px;
  border: 1px solid #cacaca;
  border-radius: 7px;
}

.menu {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
}

.iconDots {
  width: 12px;
  fill: #929292;

  .menu:hover & {
    fill: #333;
  }
}

.popup {
  position: absolute;
  right: 15px;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 8px 22px 8px 8px;
  color: #333;
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.12);
}

.buttonDelete,
.buttonEdit {
  justify-content: flex-start !important;
}

.buttonClose {
  position: absolute;
  top: 5px;
  right: 5px;
}

.iconClose {
  width: 9px;
  height: auto;
}

.confirmModal {
  padding: 44px 66px;
}

.columnInner {
  flex-direction: row;
  padding: 0;
  border: none;
}
