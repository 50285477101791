.container {
  padding: 40px;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 28px;
}

.main {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
