.shadow {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.56);
}

.body {
  position: relative;
  padding: 64px 84px;
  background-color: #f5f5f5;
  border-radius: 11px;
}

.buttonClose {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 20px;
  height: 20px;
}

.iconClose {
  width: 12px;
  fill: #929292;

  .buttonClose:hover & {
    fill: #333;
  }
}
