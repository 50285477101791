.comment {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 4px;
}

.name {
  font-size: 14px;
  font-weight: 400;
  line-height: 15px;
  color: #333;
}

.icon {
  width: 12px;
  height: auto;
  fill: #929292;
  opacity: 0;

  .comment:hover & {
    opacity: 1;
  }

  .close:hover & {
    fill: #565656;
  }
}

.text {
  grid-column: 1 / 3;
  font-size: 12px;
  font-weight: 400;
  line-height: 19px;
  color: #565656;
}

.confirmModal {
  padding: 44px 66px;
}

.columnInner {
  flex-direction: row;
  padding: 0;
  border: none;
}
