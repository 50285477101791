.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #333;
  background-color: rgba(255, 228, 69, 1);
  border-radius: 5px;
  user-select: none;
  transition: var(--default-transition);

  & > .icon {
    transition: var(--default-transition);

    & + .title {
      margin-left: 6px;
    }
  }

  &:hover {
    color: #111;
    background-color: rgba(255, 228, 69, var(--default-high-opacity));
    cursor: pointer;
  }
}

.wide {
  width: 100%;
}

.small {
  padding: 8px 11px;
  border: 1px solid #929292;
  border-radius: 3px;
}

.skinny {
  padding: 4px;
  border-radius: 3px;
}

.white {
  background-color: rgba(255, 255, 255, 1);
  box-shadow: var(--default-shadow);

  &:hover {
    background-color: rgba(66, 66, 66, var(--default-low-opacity));
  }
}

.transparent {
  background-color: transparent;

  &:hover {
    background-color: rgba(51, 51, 51, var(--default-low-opacity));
  }
}

.plus {
  width: 12px;
}

.back {
  width: 35px;
}

.plus,
.back {
  stroke: #333;

  .button:hover & {
    stroke: #111;
  }
}
