.list {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;

  &.editMode {
    gap: 8px;
  }
}

.tag {
  position: relative;
  width: 40px;
  height: 20px;

  .editMode & {
    width: 60px;
    height: 24px;
    border-radius: 2px;

    &:hover .buttonClose {
      opacity: 1;
    }
  }
}

.buttonClose {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

.iconClose {
  width: 7px;
  height: 7px;
  fill: #929292;

  .buttonClose:hover & {
    fill: #333;
  }
}
