.tag {
  display: inline-flex;
  min-width: 40px;
  min-height: 18px;
  padding: 3px 6px;
  border-radius: 2px;
}

.violet {
  background-color: #eee1fd;
}

.green {
  background-color: #baf8cf;
}

.red {
  background-color: #fed6cc;
}

.orange {
  background-color: #ffdcb6;
}

.blue {
  background-color: #b8e6ff;
}

.lightgreen {
  background-color: #d8fcb0;
}

.darkblue {
  background-color: #c6d9ff;
}

.yellow {
  background-color: #fff4c7;
}
