.row,
.column {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 720px;
  margin: 0 auto;
  gap: 20px;
  padding: 20px;
}

.column {
  flex-direction: column;
}
