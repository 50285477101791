.form {
  display: flex;
  flex-direction: column;
  gap: 14px;

  &.modal {
    width: 100vh;
    max-width: 312px;
  }
}

.fields {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.addCommentButton {
  align-self: flex-start;
}
