.multiselect {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 168px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  padding: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #333;
  background: rgba(227, 227, 227, 1);
  border-radius: 3px;
  transition: var(--default-transition);
  user-select: none;

  &:hover {
    color: #111;
    background: rgba(227, 227, 227, var(--default-high-opacity));
    cursor: pointer;

    & .arrow {
      fill: #111;
    }
  }
}

.arrow {
  fill: #333;
  transition: var(--default-transition);
}

.body {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  max-height: 0;
  padding: 10px;
  background: #d8d7d7;
  border-radius: 0px 0px 3px 3px;
  opacity: 0;
  overflow: hidden;
  transition: var(--default-transition);
}

.tagList {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 126px;
  padding-right: 16px;
  overflow-y: scroll;
}

.tag {
  width: 100%;
  height: 24px;
}

.toggle {
  position: absolute;
  z-index: -1;
  opacity: 0;

  &:checked ~ .body {
    max-height: 146px;
    opacity: 1;
  }

  &:checked ~ .header {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    & .arrow {
      transform: rotate(180deg);
    }
  }
}

.scrollbar {
  --thumb-bg: rgba(182, 182, 182, 0.99);
  --track-bg: #eaeaea;

  scrollbar-width: thin;
  scrollbar-color: var(--thumb-bg) var(--track-bg);

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--thumb-bg);
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--track-bg);
    border-radius: 3px;
  }
}
