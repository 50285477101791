:root {
  --default-transition: all 0.3s ease-out;
  --default-high-opacity: 0.7;
  --default-low-opacity: 0.05;
  --default-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
}

*,
*:before,
*:after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f5f5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  background-color: transparent;
  border: none;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
  appearance: button;
  -webkit-appearance: button;
}

a,
a:hover {
  text-decoration: none;
}
