.textarea {
  width: 100%;
  padding: 12px;
  font-size: 15px;
  font-weight: 400;
  line-height: 15px;
  color: #333;
  background-color: #fff;
  box-shadow: var(--default-shadow);
  border-radius: 4px;
  resize: none;

  &::placeholder {
    color: #565656;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.comment {
    font-size: 12px;
    line-height: 14px;
  }
}

.oneRow {
  height: 39px;
  white-space: nowrap;
  overflow-x: scroll;
}
